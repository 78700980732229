
const LocaleDropdown = () => import("@/components/LocaleDropdown.vue")
import { VerjiGrowthBook, GrowthbookFeatureFlags } from "@/utilities/growthbook"
import Vue from "vue"

export default Vue.extend({
	name: "UserMenu",
	components: {
		LocaleDropdown
	},
    data(): {
		isManageTenantsAllowed: boolean

	} {
		return {
			isManageTenantsAllowed: false,
		}
	},
    created() {
        this.isManageTenantsAllowed = VerjiGrowthBook.getInstance().isOn(GrowthbookFeatureFlags.ManageTenants)
    },
	computed: {
		hasContext(): boolean {
			return this.$vStore.state.context.selectedCustomer !== null
		},
		avatarInitials(): string {
			return this.$vStore.state.oidcStore.user ? this.$vStore.state.oidcStore.user.preferred_username.charAt(0).toUpperCase() : ""
		},
		userName(): string {
			return this.$vStore.state.oidcStore.user ? this.$vStore.state.oidcStore.user.preferred_username : ""
		},
		customerName(): string {
			if (this.$vStore.state.context.selectedCustomer) {
				return this.$vStore.state.context.selectedCustomer.name
			} else {
				return "none selected"
			}
		},
        isTenantOnboarder(): boolean {

            return this.$vStore.state.context.anyDomainPermissions ? this.$vStore.state.context.anyDomainPermissions.isTenantOnboarder : false
        }
	},
	methods: {
		loadContext(): void {
			this.$router.push('/context')
		},
        loadManageOrganizations(): void {
			this.$router.push({path:'/tenant'})
		}
        
	}
})
